
/**
 * SITE_URL
 */
const siteUrl = process.env.SITE_URL || 'https://www.anhil-fashion.de'
export const SITE_URL = siteUrl.replace(/\/$/, '')

/**
 * Config values object definition
 */
export interface IConfigValues {
  SITE_URL: string;
}

/**
 * Config values object
 */
const ConfigValues: IConfigValues = {
  SITE_URL,
}

/**
 * Config object
 */
const Config = {
  ...ConfigValues,
}

export default Config
