import classnames from 'classnames'
import { isPresent } from 'lib/utils'
import isNaN from 'lodash/isNaN'
import isNumber from 'lodash/isNumber'
import { FC, ReactNode, useMemo } from 'react'

interface ColProps {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
  offsetXs?: number;
  offsetSm?: number;
  offsetMd?: number;
  offsetLg?: number;
  offsetXl?: number;
  offsetXxl?: number;
  children?: ReactNode;
  className?: string;
}

const Col: FC<ColProps> = ({
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  offsetXs,
  offsetSm,
  offsetMd,
  offsetLg,
  offsetXl,
  offsetXxl,
  children,
  className,
}) => {
  const classes = useMemo(() => classnames({
    'position-relative': true,
    'col': true,
    [`col-${xs || ''}`]: isNumber(xs) && !isNaN(xs),
    [`col-sm-${sm || ''}`]: isNumber(sm) && !isNaN(sm),
    [`col-md-${md || ''}`]: isNumber(md) && !isNaN(md),
    [`col-lg-${lg || ''}`]: isNumber(lg) && !isNaN(lg),
    [`col-xl-${xl || ''}`]: isNumber(xl) && !isNaN(xl),
    [`col-xxl-${xxl || ''}`]: isNumber(xxl) && !isNaN(xxl),
    [`offset-${offsetXs || ''}`]: isNumber(offsetXs) && !isNaN(offsetXs),
    [`offset-sm-${offsetSm || ''}`]: isNumber(offsetSm) && !isNaN(offsetSm),
    [`offset-md-${offsetMd || ''}`]: isNumber(offsetMd) && !isNaN(offsetMd),
    [`offset-lg-${offsetLg || ''}`]: isNumber(offsetLg) && !isNaN(offsetLg),
    [`offset-xl-${offsetXl || ''}`]: isNumber(offsetXl) && !isNaN(offsetXl),
    [`offset-xxl-${offsetXxl || ''}`]: isNumber(offsetXxl) && !isNaN(offsetXxl),
    [className || '']: isPresent(className),
  }), [
    className,
    lg,
    md,
    offsetLg,
    offsetMd,
    offsetSm,
    offsetXl,
    offsetXs,
    offsetXxl,
    sm,
    xl,
    xs,
    xxl
  ])

  return (
    <div className={classes}>
      {children}
    </div>
  )
}

export default Col
