import classnames from 'classnames'
import { isPresent } from 'lib/utils'
import { FC, ReactNode, useMemo } from 'react'

interface RowProps {
  children?: ReactNode;
  className?: string;
}

const Row: FC<RowProps> = ({
  children,
  className,
}) => {
  const classes = useMemo(() => classnames({
    'row': true,
    [className || '']: isPresent(className),
  }), [className])

  return (
    <div className={classes}>
      {children}
    </div>
  )
}

export default Row
