import type { NextPage } from 'next'
import ImgUrl from 'assets/images/full_anhil_fashion_splash_background_M_700x600.png'
import { Col, Container, Link, Row } from 'atoms'
import { Layout, Main } from 'layout'
import { BiLogoEtsy, BiLogoInstagramAlt } from 'react-icons/bi'
import Head from 'next/head'
import Image from 'next/image'
import Styles from './index.module.sass'

const ETSY_URL = 'https://www.etsy.com/de/shop/anhilfashion'
const INSTA_URL = 'https://instagram.com/anhil.fashion'

const Home: NextPage = () => (
  <Layout>
    <Head>
      <title>anhil.fashion</title>
      <meta name="description" content="anhil.fashion" />
    </Head>
    <Main>
      <Container>
        <Row>
          <Col className="text-center">
            <h1 className="text-secondary">
              Handgearbeitete Kindermode
            </h1>
            <div style={{ maxWidth: '100%' }}>
              <Image
                src={ImgUrl}
                alt="anhil.fashion"
                width={700}
                height={600}
                // layout="fill"
                // objectFit="cover"
                placeholder="blur"
              />
            </div>
            <hr />
            <div className={Styles.socialMediaLinks}>
              <a href={ETSY_URL} target='_blank' rel="noreferrer" className='etsy'>
                <div className="image"></div>
                <BiLogoEtsy />
                <span>
                  Shop
                </span>
              </a>
              <a href={INSTA_URL} target='_blank' rel="noreferrer"  className='insta'>
                <div className="image"></div>
                <BiLogoInstagramAlt />
                <span>
                  Instagram
                </span>
              </a>
            </div>
            <hr />
            <Link href="/impressum">
              Impressum
            </Link>
          </Col>
        </Row>
      </Container>
    </Main>
  </Layout>
)

export default Home
