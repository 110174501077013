import { FC, ReactNode } from 'react'

import Styles from './Main.module.sass'

interface MainProps {
  children?: ReactNode,
}

const Main: FC<MainProps> = ({ children }) => (
  <main className={Styles.main}>
    {children}
  </main>
)

export default Main
