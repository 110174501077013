import { FC, ReactNode } from 'react'
import ReactModal from 'react-modal'

import Styles from './Layout.module.sass'

ReactModal.setAppElement('#__next')

interface LayoutProps {
  children?: ReactNode;
}

const Layout: FC<LayoutProps> = ({
  children,
}) => (
  <div className={Styles.wrapper}>
    {children}
  </div>
)

export default Layout
